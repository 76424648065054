import { inject, Injector, runInInjectionContext } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { from, Observable, of } from "rxjs";
import { first, mergeMap, switchMap, takeWhile } from "rxjs/operators";
import { RestrictIfService } from "./restrict-if.service";

export type ValidateFn = (route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) => boolean | Observable<boolean>;

/**
 * Creates an object with two methods: `restrictIfNot` and `oneOf`.
 * 
 * To use it, inject the factory and call the restrictIfNot method with the permission you want to check.
 *
 * e.g. 
 * ```
 *  const { restrictIfNot } = restrictIfNotFactory();
 *  const canActivate = restrictIfNot('VIEW_PRODUCT_DATA');
 * ```
 * 
 * To combine multiple permissions, use the oneOf method.
 * 
 * e.g.
 * ```
 *  const { oneOf, restrictIfNot } = restrictIfNotFactory();
 *  const canActivate = oneOf(
 *     restrictIfNot('SOME_PERMISSION', (route: ActivatedRouteSnapshot) => route.params.id === 'new'),
 *     restrictIfNot('SOME_CONDITIONAL_PERMISSION', (route: ActivatedRouteSnapshot) => route.params.id !== 'new')
 *  );
 * ```
 * 
 * The callback function passed will ensure that the permission is only checked if the condition is met.
 */
export const restrictIfFactory = () => {

    let service: RestrictIfService;
    
    return {
        /**
         *  Restricts access to a route based on the permission provided.
         * @param permission It is the permission to check.
         * @param validate It is a callback function that will be called before checking the permission. If it returns false, the permission will not be checked.
         * @returns 
         */
        restrictIfNot: (permission: string, validate?: ValidateFn): ValidateFn => 
                (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
            if (!service) {
                service = inject(RestrictIfService);
            }

            if (!validate) {
                validate = () => of(true);
            }

            let validateResult = validate(route, state);
            if (typeof validateResult === 'boolean') {
               validateResult = of(validateResult);
            }

            return validateResult.pipe(
                switchMap(result => result ? service.restrictTo(permission) : of(false))
            );
        },
      
        /**
         *  Restricts access to a route based on the permission provided.
         * @param role It is the role to check.
         * @param validate It is a callback function that will be called before checking the permission. If it returns false, the permission will not be checked.
         * @returns
         */
        restrictIfNotRole: (role: string, validate?: ValidateFn): ValidateFn =>
            (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
              if (!service) {
                service = inject(RestrictIfService);
              }
              
              if (!validate) {
                validate = () => of(true);
              }
              
              let validateResult = validate(route, state);
              if (typeof validateResult === 'boolean') {
                validateResult = of(validateResult);
              }
              
              return validateResult.pipe(
                  switchMap(result => result ? service.hasRoles(role) : of(false))
              );
            },

        /**
         * Combines multiple permissions into one.
         * @param restrictions It is a list of permissions to check.
         * @returns A function that will check all the permissions and return true if at least one of them is true.
         */
        oneOf: (...restrictions: CanActivateFn[]): ValidateFn => 
                (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
                    const injector = inject(Injector);

                    return from(restrictions).pipe(
                        mergeMap((guard: CanActivateFn) =>
                            runInInjectionContext(injector, () => guard(route, state) as Observable<boolean>)
                        ),
                        first(result => result, false),
                        takeWhile(result => !result, true), 
                    )
                }
    }
}